<template>
  <div class="nav-bar">
    <router-link tag="h1" to='' class="nav-bar__title">{{ $t('manage-events') }}</router-link>
    <div class="nav-bar__links">
      <router-link
          v-for="(link, index) in links"
          :to="link.path"
          :key="index"
          class="nav-bar__links-link"
      >
        {{ link.text }}
      </router-link>
    </div>
    <ui-menu v-model="visibleSettings">
      <template #activator>
        <img src="@/assets/svg/icon-settings-black.svg" alt="">
      </template>
      <template #body>
        <div class="sort">
          <router-link
              v-for="(link, index) in links"
              :to="link.path"
              :key="index"
              class="nav-bar__links-link"
              @click.native="visibleSettings = false"
          >
            {{ link.text }}
          </router-link>
        </div>
      </template>
    </ui-menu>
  </div>
</template>

<script>
import UiMenu from "@/components/ui/UiMenu.vue";

export default {
  name: "NavBar",
  components: {UiMenu},
  data() {
    return {
      links: [
        { id: 0, text: this.$t('create-an-event'), path: '/add-event-management' },
      ],
      visibleSettings: false
    }
  },
}
</script>

<style lang="scss" scoped>
.nav-bar {
  position: relative;

  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
    z-index: 11;
  }

  img {
    @media (min-width: 1200px) {
      display: none;
    }
  }

  &__title {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
    white-space: nowrap;
    cursor: pointer;

    @media (max-width: 1200px) {
      margin-bottom: 0;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: 1200px) {
      display: none;
    }

    .router-link-active {
      color: red;
    }

    &-link {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #343432;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}
</style>
